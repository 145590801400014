import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { API } from "@core/helpers/API";
import { ApiConfiguration } from "@core/types/api/api-configuration";


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _apis: {
    [key: string]: API
  } = {};

  constructor(
    private _http: HttpClient,
  ) {
    Object.keys(environment.apis).forEach((key: string) => {
      const api: ApiConfiguration = environment.apis[key];
      this._apis[key] = new API(api, this._http);
    });
  }

  getApi(name: string): API | undefined {
    return this._apis[name];
  }

  make(name: string, endpoint: string, params?: any, options?: any): Promise<any> {
    const api: API | undefined = this.getApi(name);
    return new Promise(async (resolve, reject) => {
      if (!api) {
        return reject(`API ${name} not found`);
      }
      const request = await api.make(endpoint, params, options);
      request.subscribe({
        next: (v) => resolve(v),
        error: (e) => reject(e)
      });
    })
  }

  stopAllTheRequests() {
    Object.keys(this._apis).forEach((key: string) => {
      this._apis[key].stopAllTheRequests();
    });
  }
}

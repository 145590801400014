import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateHumanized'
})
export class DateHumanizedPipe implements PipeTransform {
  transform(date: any): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const now = new Date();
    if (now.getFullYear() !== date.getFullYear()) {
      return date.toLocaleDateString();
    } else if (now.getMonth() !== date.getMonth()) {
      return getDayMonth(date);
    } else if (now.getDate() !== date.getDate()) {
      const days = Math.ceil((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      if (days === 1) {
        return 'Domani';
      } else if (days < 7) {
        const dayNames = ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];
        return dayNames[date.getDay()];
      } else {
        return getDayMonth(date);
      }
    } else {
      return 'Oggi';
    }
  }
}

export const getDayMonth = (date: Date) => {
  const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  const monthsShort = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
  return date.getDate().toString().padStart(2, '0') + ' ' + monthsShort[date.getMonth()];
}

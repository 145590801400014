import { ApiConfiguration } from "@core/types/api/api-configuration";
import { EndpointConfiguration } from "@core/types/api/endpoint-configuration";
import { HttpClient } from "@angular/common/http";

export class API {
  // private
  private readonly _baseUrl: string
  private readonly _devUrl: string
  private readonly _isDev: boolean
  private readonly _isFake: boolean
  private readonly _useCredentials: boolean
  private readonly _token: string | undefined
  private readonly _xApplicationToken: string | undefined
  private readonly _endpoints: {
    [key: string]: EndpointConfiguration
  }
  private readonly _http: HttpClient

  // public

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get baseUrl(): string {
    return this._baseUrl;
  }

  get devUrl(): string {
    return this._devUrl;
  }

  get isDev(): boolean {
    return this._isDev;
  }

  get isFake(): boolean {
    return this._isFake;
  }

  get endpoints(): { [key: string]: EndpointConfiguration } {
    return this._endpoints;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------
  constructor(config: ApiConfiguration, http: HttpClient) {
    this._baseUrl = config.baseUrl;
    this._devUrl = config.devUrl;
    this._isDev = config.isDev;
    this._isFake = config.isFake;
    this._useCredentials = config.useCredentials || false
    this._endpoints = config.endpoints;
    this._token = config.token;
    this._xApplicationToken = config['x-application-token'];
    this._http = http
  }

  // -----------------------------------------------------------------------------------------------------
  // @ public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the endpoint by name
   *
   * @param name string
   */
  private _getEndpoint(name: string): EndpointConfiguration {
    const endpoint = this._endpoints[name];
    if (!endpoint) {
      throw new Error(`Endpoint ${name} not found`);
    }
    return endpoint;
  }

  /**
   * Get the url of the endpoint
   *
   * @param endpoint EndpointConfiguration
   * @param params Object | undefined
   * @returns string
   * @throws Error
   * @private
   */
  private _getUrl(endpoint: EndpointConfiguration, params?: any): string {
    let url = (this._isDev ? this._devUrl : this._baseUrl) + endpoint.url;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.replace('{' + key + '}', params[key]);
      });
    }
    return url;
  }

  /**
   * Get the options for the request and if necessary get the token and add it to the headers
   *
   * @param endpoint
   * @param options Object
   */
  private _getOptions(endpoint: EndpointConfiguration, options: any = {}) {
    if (this._useCredentials) {
      options.withCredentials = true;
    }
    if (endpoint.useCredentials !== undefined) {
      options.withCredentials = endpoint.useCredentials;
    }
    return options;
  }

  /**
   * Build the params for the request with the syntax `{key}` replaced with the value
   *
   * @param params Object
   * @param data Object
   * @param currentPath string
   * @private
   */
  private _buildHttpParams(params: any, data: any, currentPath: string) {
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        this._buildHttpParams(params, data[key], `${currentPath}${key}.`);
      } else {
        params[`${currentPath}${key}`] = data[key];
      }
    });
  }

  /**
   * Use to build the query params for the request
   *
   * @private
   * @param obj
   * @param parentKey
   */
  public flattenObject(obj: any, parentKey = '') {
    let result: any = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let newKey = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursively flatten nested objects
          Object.assign(result, this.flattenObject(obj[key], newKey));
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  /**
   * Make the request
   *
   * @param endpointName
   * @param params
   * @param options
   */
  async make(endpointName: string, params?: any, options?: any) {
    const endpoint = this._getEndpoint(endpointName);
    options = await this._getOptions(endpoint, options);
    const url = this._getUrl(endpoint, params);
    const queryParams = new URLSearchParams(this.flattenObject(options.params)).toString();
    delete options.params;
    if (this._xApplicationToken) {
      options.headers = options.headers || {};
      options.headers['x-application-token'] = this._xApplicationToken;
    }
    return this._http.request(endpoint.method, `${url}${queryParams ? '?' + queryParams : ''}`, options);
  }

  stopAllTheRequests() {
    this._http.get('/').subscribe();
    console.log('All the requests stopped')
  }
}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {SvgIconComponent} from "../@core/components/svg-icon/svg-icon.component";
import { ThemeLogoService} from "./services/theme-logo.service";
import {RequestBlocker} from "../@core/services/requestBlocker.service";
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from '@ctrl/ngx-rightclick';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {CardSnippetModule} from '@core/components/card-snippet/card-snippet.module';

import {coreConfig} from 'app/app-config';
// import { AuthGuard } from 'app/auth/helpers/auth.guards';
// import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
// import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';

import {NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig} from 'ngx-zendesk-webwidget';
import {CategoriesModule} from "./main/categories/categories.module";
import {fakeBackendProvider} from "../@dev/fake-backend";
import {environment} from "../environments/environment";
import {AuthGuard} from "./main/authentication/AuthGuard";
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

window['zESettings'] = {
  webWidget: {
    offset: { vertical: '-8px' },
    color: {
      theme: 'var(--primary)',
      launcherText: '#FFFFFF'
    }
  }
};

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = false;
  accountUrl = 'tulero.zendesk.com';
  callback(zE) {
    // You can call every command you want in here
    // zE('webWidget', 'hide');
    // zE('webWidget', 'show');
    zE('webWidget', 'hide');
    zE.setLocale('it');
  }
}
// import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
// import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
// import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
// import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'home',
    loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'products',
    loadChildren: () => import('./main/products/products.module').then(m => m.ProductsModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'cart',
    loadChildren: () => import('./main/cart/cart.module').then(m => m.CartModule)
  },

  {
    canActivate: [AuthGuard],
    path: 'categories',
    loadChildren: () => import('./main/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'order',
    loadChildren: () => import('./main/order/order.module').then(m => m.OrderModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'addresses',
    loadChildren: () => import('./main/addresses/addresses.module').then(m => m.AddressesModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'subscription',
    loadChildren: () => import('./main/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'WalletHistory',
    loadChildren: () => import('./main/wallet-history/wallet-history.module').then(m => m.WalletHistoryModule)
  },
  {

    path: '',
    loadChildren: () => import('./main/static/static.module').then(m => m.StaticModule)
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SvgIconComponent,
    // ContextMenuComponent,
    // BasicCustomContextMenuComponent,
    // AnimatedCustomContextMenuComponent,
    // SubMenuCustomContextMenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ],
  providers: [
    ThemeLogoService,
    ...(environment.production ? [] : [fakeBackendProvider]),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestBlocker,
      multi: true,
    },
  ],
  exports: [
    SvgIconComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

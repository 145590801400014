import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService} from "../../auth/service";
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate():  Promise<boolean> {
    return this.checkAuth();
  }

  private checkAuth(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authService.isAuthenticated()
        .then((response: any) => {
          if (response.status === 404) {
            console.log('User not authenticated');
            this.router.navigate(['/authentication/login']);
            resolve(false);
          } else if (response.status === 401) {
            console.log('Business not authenticated');
            this.router.navigate(['/authentication/business-selection']);
            resolve(false);
          } else {
            console.log('User authenticated');
            resolve(true);
          }
        })
        .catch((error: any) => {
          resolve(false);
        });
    });
  }
}

import { color } from "@core/types/app/color";

export type Theme = {
  app: {
    name: string;
    title: string;
    logo: {
      light?: string;
      dark?: string;
      lightSquare?: string;
      darkSquare?: string;
    };
    icon: {
      light: string;
      dark: string;
    };
    language: 'it' | 'en';
  };
  layout: {
    skin: ThemeSkin;
    animation: ThemeAnimation
    header: {
      hidden: boolean;
    };
    sidebar: {
      hidden: boolean;
    };
    footer: {
      hidden: boolean;
    }
  };
  colors: {
    primary: color;
    secondary: color;
    success: color;
    info: color;
    warning: color;
    danger: color;
  };
  sections?: string[]
}

export enum ThemeSkin {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum ThemeAnimation {
  FADE_IN_LEFT = 'fadeInLeft',
  ZOOM_IN = 'zoomIn',
  FADE_IN = 'fadeIn',
  NONE = 'none'
}

import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';
import { PricePipe } from '@core/pipes/price.pipe';
import { MarginRelativePipe } from '@core/pipes/marginRelative.pipe';
import { ToNumberPipe } from '@core/pipes/toNumber.pipe';
import { IsURLPipe } from '@core/pipes/isURL.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { DateHumanizedPipe } from '@core/pipes/dateHumanized.pipe';
import { ReducePipe } from '@core/pipes/reduce.pipe';

@NgModule({
  declarations: [InitialsPipe, PricePipe, MarginRelativePipe, ToNumberPipe, IsURLPipe, DateHumanizedPipe, ReducePipe, FilterPipe, StripHtmlPipe, SafePipe],
  imports: [],
  exports: [InitialsPipe, PricePipe, MarginRelativePipe, ToNumberPipe, IsURLPipe, DateHumanizedPipe, ReducePipe, FilterPipe, StripHtmlPipe, SafePipe]
})
export class CorePipesModule {}

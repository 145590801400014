import {Component, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ShopB2bService} from "app/services/shop-b2b.service";
import {SalesService} from "../../../../services/sales.service";

@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  // Public
  public products = [];
  public cartList = [];
  public cartListLength;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param _shopB2bService
   */
  constructor(
    private _salesService: SalesService,
    private _shopB2bService: ShopB2bService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove From Cart
   *
   * @param product
   */
  removeFromCart(product) {
    if (product.isInCart === true) {
      // this._ecommerceService.removeFromCart(product.id).then(res => {
      //   product.isInCart = false;
      // });
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._salesService.cart.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      if (data) {
        this.updateCartLength(data);
      }
    });

    this._salesService.getCart();


    this._shopB2bService.getBehaviorSubject('cart').pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.cartListLength = data.total;
    });
    this._shopB2bService.getCart('cart')

    // Get Products
    // this._ecommerceService.getProducts();
    //
    // // Get Cart List
    // this._ecommerceService.getCartList();
    //
    // // Subscribe to Cart List
    // this._ecommerceService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.cartList = res;
    // });

    // Subscribe to ProductList change
    // this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.products = res;
    //
    //   if (this.products.length) {
    //     // update product is in CartList : Boolean
    //     this.products.forEach(product => {
    //       product.isInCart = this.cartList.findIndex(p => p.productId === product.id) > -1;
    //     });
    //   }
    // });
  }


  updateCartLength(data: any): void {
    console.log("cart data", data);
    this.cartListLength = data.suppliers.reduce((acc, supplier) => {
      return acc + supplier.products.reduce((acc, product) => {
        if (!product.type || product.type !== 'system') {
          return acc + product.quantity;
        }
        return acc;
      }, 0);
    }, 0);
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

// backend-interceptor.service.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RequestBlocker implements HttpInterceptor {
  private blockRequests = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.blockRequests) {
      return throwError('Requests are blocked temporarily');
    }

    return next.handle(req).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  blockRequestsForOneSecond() {
    this.blockRequests = true;
    setTimeout(() => {
      this.blockRequests = false;
    }, 1000);
  }
}

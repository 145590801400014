import { Group } from "../@core/types/data/group";

export const list: Group[] = [
  {
    "id": "6552e9bfab56560ce03f251c",
    "code": "default",
    "name": "Default",
    "description": "",
    "default": {
      "priceRules": [
        {
          "from": 0,
          "markUp": 40
        }
      ],
      "promotions": [
        {
          "filters": {
            "brand": "TUL",
            "category": "OIL"
          },
          "priceRules": [
            {
              "from": 0,
              "markUp": 10
            }
          ]
        },
        {
          "filters": {
            "category": "OIL"
          },
          "priceRules": [
            {
              "from": 0,
              "markUp": 25
            }
          ]
        },
        {
          "filters": {
            "category": "BATTERY"
          },
          "priceRules": [
            {
              "from": 0,
              "markUp": 25
            }
          ]
        }
      ]
    },
    "suppliers": [
      {
        "supplier": {
          "id": "646f115b9299a46064889494",
          "code": "A20",
          "name": "prasco",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 65
          },
          {
            "from": 10,
            "markUp": 60
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397cafb7adeea5ae182f946",
          "code": "levante",
          "name": "bori",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 40
          },
          {
            "from": 10,
            "markUp": 32
          },
          {
            "from": 20,
            "markUp": 28
          },
          {
            "from": 30,
            "markUp": 25
          },
          {
            "from": 300,
            "markUp": 18
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca8b7adeea5ae182f93e",
          "code": "solano",
          "name": "midac",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 37.5
          },
          {
            "from": 10,
            "markUp": 32
          },
          {
            "from": 20,
            "markUp": 28
          },
          {
            "from": 30,
            "markUp": 27
          },
          {
            "from": 200,
            "markUp": 22
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6474aaf2e4da99da8c7da1e0",
          "code": "A22",
          "name": "cida-prasco",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 65
          },
          {
            "from": 10,
            "markUp": 60
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca4f7adeea5ae182f936",
          "code": "libeccio",
          "name": "cida",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 65
          },
          {
            "from": 10,
            "markUp": 60
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397cb087adeea5ae182f948",
          "code": "bora",
          "name": "sidat",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 65
          },
          {
            "from": 10,
            "markUp": 60
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "644245b7b6214198356e6f6a",
          "code": "A19",
          "name": "sidat-stellare",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 65
          },
          {
            "from": 10,
            "markUp": 60
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca7f7adeea5ae182f93c",
          "code": "alisei",
          "name": "dipa",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 38
          },
          {
            "from": 10,
            "markUp": 32
          },
          {
            "from": 20,
            "markUp": 28
          },
          {
            "from": 30,
            "markUp": 25
          },
          {
            "from": 300,
            "markUp": 18
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca3c7adeea5ae182f934",
          "code": "maestrale",
          "name": "idir",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 60
          },
          {
            "from": 10,
            "markUp": 55
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 38
          },
          {
            "from": 200,
            "markUp": 32
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397c9cf7adeea5ae182f930",
          "code": "scirocco",
          "name": "cati",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 60
          },
          {
            "from": 10,
            "markUp": 55
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 33
          },
          {
            "from": 200,
            "markUp": 27
          },
          {
            "from": 300,
            "markUp": 19
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca1a7adeea5ae182f932",
          "code": "marino",
          "name": "cati-stellare",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 60
          },
          {
            "from": 10,
            "markUp": 55
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 33
          },
          {
            "from": 200,
            "markUp": 27
          },
          {
            "from": 300,
            "markUp": 19
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397ca977adeea5ae182f940",
          "code": "cauro",
          "name": "eneos",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 17
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6397caa17adeea5ae182f942",
          "code": "eolo",
          "name": "serbatoigpl",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 45
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "63a4dc42dd92172d55ae2e25",
          "code": "tulero",
          "name": "tulero",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 10
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "63fd751f77647191bf7eb946",
          "code": "ponente",
          "name": "franceschini",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 25
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "63f74931b19efc3df2a2fd8a",
          "code": "grecale",
          "name": "ovam",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 60
          },
          {
            "from": 10,
            "markUp": 55
          },
          {
            "from": 20,
            "markUp": 50
          },
          {
            "from": 30,
            "markUp": 39
          },
          {
            "from": 50,
            "markUp": 33
          },
          {
            "from": 200,
            "markUp": 27
          },
          {
            "from": 300,
            "markUp": 19
          },
          {
            "from": 500,
            "markUp": 16
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6417299ce5f117b28f96f32f",
          "code": "A17",
          "name": "felappi",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 5
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6420f25252b3deb274772426",
          "code": "A18",
          "name": "felappi-stellare",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 5
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "64770e956ed1c0b10e088c76",
          "code": "A23",
          "name": "idir-stellare",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "648843aef87a858df07d1bc0",
          "code": "A24",
          "name": "euroglass",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 55
          },
          {
            "from": 10,
            "markUp": 50
          },
          {
            "from": 20,
            "markUp": 45
          },
          {
            "from": 30,
            "markUp": 34
          },
          {
            "from": 50,
            "markUp": 33
          },
          {
            "from": 200,
            "markUp": 27
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "648843cdf87a858df07d1bc2",
          "code": "A26",
          "name": "abs",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 55
          },
          {
            "from": 10,
            "markUp": 50
          },
          {
            "from": 20,
            "markUp": 45
          },
          {
            "from": 30,
            "markUp": 34
          },
          {
            "from": 50,
            "markUp": 33
          },
          {
            "from": 200,
            "markUp": 27
          },
          {
            "from": 300,
            "markUp": 24
          },
          {
            "from": 500,
            "markUp": 20
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "648843bbf87a858df07d1bc1",
          "code": "A25",
          "name": "action",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 50
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "6488441df87a858df07d1bc3",
          "code": "A27",
          "name": "viskoil",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 30
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "65290989563656752d0ec4c6",
          "code": "A29",
          "name": "commercialericambi",
          "logo": "assets/images/logo/logo-light.png"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 25
          }
        ],
        "promotions": []
      },
      {
        "supplier": {
          "id": "65362091afe1d1d370fda584",
          "code": "A31",
          "name": "gammaplast"
        },
        "priceRules": [
          {
            "from": 0,
            "markUp": 60
          }
        ],
        "promotions": []
      }
    ]
  }
]


export const viewFromCode = (body: any, fakeSource: any): any => {
  const code = fakeSource[2];
  return list.find(group => group.code === code);
}


export const edit = (body: Group, fakeSource: any): Group | undefined => {
  const id = fakeSource[2];
  const group: Group | undefined = list.find(group => group.id === id);
  if (group) {
    group.name = body.name;
    group.description = body.description;
    group.default = body.default;
    group.suppliers = body.suppliers;
    return group
  }
  return undefined;
}

export const today = {
  data: [
    {
      "time": "05",
      "cost": 0,
      "sales": 0
    },
    {
      "time": "06",
      "cost": 45.2,
      "sales": 78.3
    },
    {
      "time": "07",
      "cost": 62.8,
      "sales": 105.7
    },
    {
      "time": "08",
      "cost": 78.5,
      "sales": 130.2
    },
    {
      "time": "09",
      "cost": 95.3,
      "sales": 157.4
    },
    {
      "time": "10",
      "cost": 120.6,
      "sales": 182.9
    },
    {
      "time": "11",
      "cost": 145.2,
      "sales": 200.5
    },
    {
      "time": "12",
      "cost": 160.7,
      "sales": 175.3
    },
    {
      "time": "13",
      "cost": 140.4,
      "sales": 150.6
    },
    {
      "time": "14",
      "cost": 120.2,
      "sales": 125.8
    },
    {
      "time": "15",
      "cost": 100.9,
      "sales": 103.4
    },
    {
      "time": "16",
      "cost": 80.6,
      "sales": 82.7
    },
    {
      "time": "17",
      "cost": 60.3,
      "sales": 60.8
    },
    {
      "time": "18",
      "cost": 45.7,
      "sales": 45.5
    },
    {
      "time": "19",
      "cost": 35.4,
      "sales": 35.9
    },
    {
      "time": "20",
      "cost": 28.9,
      "sales": 28.4
    },
    {
      "time": "21",
      "cost": 25.6,
      "sales": 23.7
    },
    {
      "time": "22",
      "cost": 22.3,
      "sales": 21.8
    },
    {
      "time": "23",
      "cost": 20.4,
      "sales": 20.2
    }
  ],
  field: "time",
  chartType: "line",
  series: [
    {
      "name": "Acquisto",
      "field": "cost",
      "color": "#490E67"
    },
    {
      "name": "Vendita",
      "field": "sales",
      "color": "#ff8300"
    }
  ]
};

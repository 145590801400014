<!-- Footer -->
<div class="bg-secondary text-white">
  <div class="content-wrapper container-xxl overflow-hidden pt-3 pb-3">
    <div class="row">
      <div class="col-lg-4 col-md-12 col-12 reassurance d-flex mt-1">
        <img src="/assets/images/footer/2.png" alt="Pagamento a 90 giorni">
        <div class="d-inline-block">
          <div class="reassurance-title">Consegna con corriere espresso</div>
          <div class="reassurance-description">Esposto per ogni magazzino</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-12 reassurance d-flex mt-1">
        <img src="/assets/images/footer/3.png" alt="Pagamento a 90 giorni">
        <div>
          <div class="reassurance-title">Consegna GRATUITA</div>
          <div class="reassurance-description">Per ordini superiori a 200,00 Euro salvo promozioni attive</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-12 reassurance d-flex mt-1">
        <img src="/assets/images/footer/4.png" alt="Pagamento a 90 giorni">
        <div>
          <div class="reassurance-title">Resi GRATUITI</div>
          <div class="reassurance-description">Compila il modulo nel tuo account</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-secondary text-white footer-block-divided">
  <div class="content-wrapper container-xxl overflow-hidden d-block justify-content-between pt-4 pb-3 d-md-flex">
    <div class="row">
      <div class="business-card col-12 col-md-5 col-lg-3">
        <img src="/assets/images/logo/logo-white.png" alt="Tulero Business" class="mb-2" width="120">
        <div class="row">
          <div class="col-6 col-md-12">
            <p class="font-italic">Tulero è un marchio di Matheria S.r.l.</p>
            <p>P.IVA: 12342520017</p>
          </div>
          <div class="col-6 col-md-12">
            <p class="mb-25">Sede Legale:</p>
            <p class="mb-25">Corso Svizzera 185 scala F</p>
            <p>10121 Torino (TO)</p>
          </div>
        </div>
      </div>
      <div class="justify-content-between common-links d-flex col-12 col-md-7 col-lg-5 business-card">
        <div class="pl-2 pr-2 d-none d-md-block">
          <h3 class="text-white mb-4">Azienda</h3>
          <dl>
            <dd><a [href]="sectionCustomWhoWeAreLink || 'https://tulero.it/chi-siamo/'">Chi siamo</a></dd>
          </dl>
          <dl>
            <dd><a href="https://tulero.it/contatti/">Contatti</a></dd>
          </dl>
          <dl>
            <dd><a href="/faq">FAQ</a></dd>
          </dl>
          <dl>
            <dd><a href="https://tulero.it/blog/">Blog</a></dd>
          </dl>
        </div>
        <div class="pl-2 pr-2 d-none d-md-block">
          <h3 class="text-white mb-4">Servizi</h3>
          <dl>
            <dd>Supporto Clienti</dd>
          </dl>
          <dl>
            <dd><a href="https://tulero.it/guida-acquisto-tulero/">Guida all'utilizzo</a></dd>
          </dl>
          <dl>
            <dd><a href="/returns-warranties">Resi e Garanzie</a></dd>
          </dl>
        </div>
        <div class="pl-0 pr-0 pl-md-1 pr-md-1 pt-1 pt-md-0">
          <h3 class="text-white mb-4 mb-sm-1">Sicurezza e Trasparenza</h3>
          <dl>
            <dd><a [href]="sectionCustomTermsLink || 'https://tulero.it/condizioni-generali-di-vendita/'">Condizioni
              generali di vendita</a></dd>
          </dl>
          <dl>
            <dd><a [href]="sectionCustomLegalLink || 'https://tulero.it/informazioni-legali/'">Informazioni legali</a>
            </dd>
          </dl>
          <dl>
            <dd><a [href]="sectionCustomPrivacyLink || 'https://tulero.it/privacy/'">Privacy Policy</a></dd>
          </dl>
          <dl>
            <dd><a href="https://tulero.it/cookies/">Utilizzo dei cookie</a></dd>
          </dl>
        </div>
      </div>
      <div class="mt-md-1 px-md-15 col-12 col-lg-4">
        <h3 class="text-white">Iscriviti alla Newsletter</h3>
        <p class="font-italic">Ricevi le nostre offerte e novità</p>
        <form class="">
          <div class="input-group">
            <input
              type="text"
              class="form-control newsletter-email"
              placeholder="Email*"
              aria-describedby="button-addon2"
            />
            <div class="input-group-append" id="button-addon2">
              <button class="btn btn-primary" type="button" rippleEffect>ISCRIVITI</button>
            </div>
          </div>
          <div class="custom-control custom-checkbox newsletter-privacy-policy mt-1">
            <input type="checkbox" class="custom-control-input" id="customCheck2"/>
            <label class="custom-control-label text-white" for="customCheck2">
              Acconsento all'utilizzo dei dati forniti per l'invio di
              Newsletter commerciali. Per ulteriori informazioni,
              consulta la nostra <a href="#">Privacy Policy</a>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="content-wrapper container-xxl overflow-hidden">
  </div>
</div>
<div class="bg-secondary bg-darken-1 p-1 pl-2 pr-2 text-white">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-start justify-content-lg-start">
        <span class="d-flex align-items-center">
          <span>COPYRIGHT &copy; {{ year }}</span>
          <a class="ml-25" href="https://tulero.it" target="_blank">Matheria s.r.l.</a>
        </span>
      </div>
      <div class="mt-1 mt-md-0 col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-end justify-content-lg-center">
        <span class="d-flex align-items-center">
          <span>{{ 'PARTNERSHIP' | translate }}</span>
          <a class="ml-25" href="https://meyler.it" target="_blank">Meyler</a>
        </span>
      </div>
      <div class="mt-1 mt-lg-0 col-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-center  justify-content-lg-end">
      <span class="d-flex align-items-center">
      SEGUICI SU
      <a href="https://www.facebook.com/matheria.it" target="_blank" class="btn btn-icon btn-white btn-sm ml-1 p-25"
         rippleEffect>
        <i data-feather="facebook" class="ml-0 text-secondary"></i>
      </a>
      <a href="https://www.linkedin.com/company/tulero/" target="_blank" class="btn btn-icon btn-white btn-sm ml-1 p-25"
         rippleEffect>
        <i data-feather="linkedin" class="ml-0 text-secondary"></i>
      </a>
    </span>
      </div>
    </div>
</div>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {ApiConfiguration} from "@core/types/api/api-configuration";
import {EndpointMethods} from "@core/types/api/endpoint-methods";

export const environment: {
  version: string;
  production: boolean;
  useLocalStorage: boolean;
  hmr: boolean;
  firebaseConfig : {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
  };
  apis: {
    [key: string]: ApiConfiguration;
  };
  b2b: any;
  auth: any;
} = {
  version: "0.9251",
  production: false,
  hmr: false,
  useLocalStorage: true,
  firebaseConfig: {
    apiKey: "AIzaSyDTHvzYYtowOLz06RyLUkOTGawoAsua3D8",
    authDomain: "tulero-996e9.firebaseapp.com",
    projectId: "tulero-996e9",
    storageBucket: "tulero-996e9.appspot.com",
    messagingSenderId: "858036704425",
    appId: "1:858036704425:web:51135274d20f38459ec5b8",
    measurementId: "G-EGVWP2CC2G"
  },
    apis: {
    application: {
      baseUrl: 'https://api.tulero.it/api/application',
      devUrl: "http://localhost:3000/api/application",
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        theme: {
          method: EndpointMethods.GET,
          url: "/theme",
          fakeSource: "theme.current"
        },
        menu: {
          method: EndpointMethods.GET,
          url: "/menu",
        }
      }
    },
    auth: {
      baseUrl: 'https://api.tulero.it/api/users/account',
      devUrl: 'http://localhost:3000/api/users/account',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        account: {
          method: EndpointMethods.GET,
          url: "",
        },
        signin: {
          method: EndpointMethods.POST,
          url: "/signin",
          fakeSource: "account.signin"
        },
        signup: {
          method: EndpointMethods.POST,
          url: "/signup",
          fakeSource: "account.signup"
        },
        signout: {
          method: EndpointMethods.GET,
          url: "/signout",
          fakeSource: "account.signout"
        },
        resetPassword: {
          method: EndpointMethods.POST,
          url: "/change-password/{email}",
          fakeSource: "account.resetPassword"
        },
        checkUsername: {
          method: EndpointMethods.GET,
          url: "/username/{username}",
          fakeSource: "account.checkUsername"
        },
        businessRegister: {
          method: EndpointMethods.POST,
          url: "/businesses",
        },
        businessView: {
          method: EndpointMethods.GET,
          url: "/businesses",
        },
        identity: {
          method: EndpointMethods.POST,
          url: "/identity",
        },
        walletHistory: {
          method: EndpointMethods.GET,
          url: "/wallets/{wallet}/history",
        },
        Alerts: {
          method: EndpointMethods.GET,
          url: "/alerts/{business}",
        },
        confirmAlert: {
          method: EndpointMethods.PUT,
          url: "/alert/confirm",
        },
      }
    },
    banners: {
      baseUrl: 'https://api.tulero.it/api/users/banners',
      devUrl: 'http://localhost:3000/api/users/banners',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
        },
      }
    },
    orders: {
      baseUrl: 'https://api.tulero.it/api/users/order',
      devUrl: 'http://localhost:3000/api/users/order',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        export: {
          method: EndpointMethods.GET,
          url: "/export/{type}",
        },
        'export-single': {
          method: EndpointMethods.GET,
          url: "/{id}/export/{type}",
        },
      }
    },
    vehicles: {
      baseUrl: 'https://api.tulero.it/api/users/vehicles',
      devUrl: 'http://localhost:3000/api/users/vehicles',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        brands: {
          method: EndpointMethods.GET,
          url: "/brands",
        },
        models: {
          method: EndpointMethods.GET,
          url: "/{brand}/models",
        },
        versions: {
          method: EndpointMethods.GET,
          url: "/{model}/{year}/versions",
        },
        vin: {
          method: EndpointMethods.GET,
          url: "/vin/{vin}",
        },
        categories: {
          method: EndpointMethods.GET,
          url: "/{version}/categories",
        },
        subcategories: {
          method: EndpointMethods.GET,
          url: "/{version}/categories/{category}",
        },
        getItems: {
          method: EndpointMethods.GET,
          url: "/{version}/categories/{category}/{subcategory}",
        },
        getCriteria: {
          method: EndpointMethods.GET,
          url: "/{version}/criterias/{part}",
        },
        getVehicleByPlate: {
          method: EndpointMethods.GET,
          url: "/plate/{plate}",
        },
        searchByVIN: {
          method: EndpointMethods.GET,
          url: "/vin/{vin}",
        },
        isVinCached: {
          method: EndpointMethods.GET,
          url: "/vin/{vin}/cached",
        },
        'isPlateCached': {
          method: EndpointMethods.GET,
          url: "/plate/{plate}/cached",
        },
      }
    },

    users: {
      baseUrl: 'https://api.tulero.it/api/admin/users',
      devUrl: 'https://api.tulero.it/api/admin/users',
      isDev: false,
      isFake: true,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        list: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "users.list"
        },
        create: {
          method: EndpointMethods.POST,
          url: "",
          fakeSource: "users.create"
        },
        update: {
          method: EndpointMethods.PUT,
          url: "/{id}",
          fakeSource: "users.update"
        },
        delete: {
          method: EndpointMethods.DELETE,
          url: "/{id}",
          fakeSource: "users.delete"
        }
      }
    },
    charts: {
      baseUrl: 'https://api.tulero.it/api/admin/charts',
      devUrl: 'https://api.tulero.it/api/admin/charts',
      isDev: false,
      isFake: true,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        lastOrders: {
          method: EndpointMethods.GET,
          url: "/orders/{type}",
          fakeSource: "lastOrders.{type}"
        }
      }
    },
    groups: {
      baseUrl: 'https://api.tulero.it/api/admin/groups',
      devUrl: 'http://localhost:3000/api/admin/groups',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "groups.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "groups.view"
        },
        edit: {
          method: EndpointMethods.POST,
          url: "/{id}",
          fakeSource: "groups.edit.{id}"
        },
        viewFromCode: {
          method: EndpointMethods.GET,
          url: "/code/{code}",
          fakeSource: "groups.viewFromCode.{code}"
        },
      }
    },
    businesses: {
      baseUrl: 'https://api.tulero.it/api/admin/businesses',
      devUrl: 'http://localhost:3000/api/admin/businesses',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "businesses.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "businesses.view"
        },
        viewFromCode: {
          method: EndpointMethods.GET,
          url: "/code/{code}",
          fakeSource: "businesses.viewFromCode.{code}"
        },
        edit: {
          method: EndpointMethods.POST,
          url: "/{id}",
          fakeSource: "businesses.edit.{id}"
        },
      }
    },
    products: {
      baseUrl: 'https://api.tulero.it/api/admin/products',
      devUrl: 'http://localhost:3000/api/admin/products',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "products.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "products.view"
        },
      }
    },
    brands: {
      baseUrl: 'https://api.tulero.it/api/admin/brands',
      devUrl: 'http://localhost:3000/api/admin/brands',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "brands.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "brands.view"
        },
      }
    },
    categories: {
      baseUrl: 'https://api.tulero.it/api/admin/categories',
      devUrl: 'http://localhost:3000/api/admin/categories',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        get: {
          method: EndpointMethods.GET,
          url: "",
          fakeSource: "categories.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "categories.view"
        },
      }
    },
    sales: {
      baseUrl: 'https://api.tulero.it/api/users/sales',
      devUrl: 'http://localhost:3000/api/users/sales',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVFVMRVJPLUIyQiJ9.nJnisA89topHND_fabdkIN3u2YzYzqw-YXHZ9Atc6jY',
      endpoints: {
        getCart: {
          method: EndpointMethods.GET,
          url: "/cart",
          fakeSource: "categories.list"
        },
        view: {
          method: EndpointMethods.GET,
          url: "/{id}",
          fakeSource: "categories.view"
        },
        setAddress: {
          method: EndpointMethods.GET,
          url: "/cart-set-address/{id}",
        },
        newAddress: {
          method: EndpointMethods.POST,
          url: "/cart-add-address",
        },
        editAddress: {
          method: EndpointMethods.GET,
          url: "/cart-set-address-name/{id}/{name}"
        },
        checkout: {
          method: EndpointMethods.POST,
          url: "/cart-checkout"
        },
        applyDiscount: {
          method: EndpointMethods.GET,
          url: "/cart-set-discount/{discount}"
        },
        removeDiscount: {
          method: EndpointMethods.GET,
          url: "/cart-remove-discount"
        },
        getDiscount: {
          method: EndpointMethods.GET,
          url: "/cart-get-discount/{discount}"
        },
        addCart: {
          method: EndpointMethods.GET,
          url: "/add-card"
        },
        buyPrime: {
          method: EndpointMethods.POST,
          url: "/subscribe-prime"
        },
        cancelSubscription: {
          method: EndpointMethods.GET,
          url: "/cancel-subscription"
        },
      }
    },
    search: {
      baseUrl: 'https://api.tulero.it/api/users/search',
      devUrl: 'http://localhost:3000/api/users/search',
      isDev: false,
      isFake: false,
      useCredentials: true,
      token: '',
      endpoints: {
        suggestion: {
          method: EndpointMethods.GET,
          url: "/suggestion/{text}",
        },
        approximateSuggestions: {
          method: EndpointMethods.GET,
          url: "/suggestion-text/{text}",
        },
        search: {
          method: EndpointMethods.GET,
          url: "/{text}",
          fakeSource: "search.{text}"
        },
        getSuppliers: {
          method: EndpointMethods.GET,
          url: "/warehouses",
          fakeSource: "orders.read"
        },
        getWarehouses: {
          method: EndpointMethods.GET,
          url: "/warehouses",
          fakeSource: "orders.read"
        },
        getSupplierProducts: {
          method: EndpointMethods.GET,
          url: "/supplier/{id}/code/{code}",
          fakeSource: "orders.read"
        },
        productTextSearch: {
          method: EndpointMethods.GET,
          url: "/products/{text}?text={searchText}&skipCheck={skipCheck}",
        },
        searchFromOEM: {
          method: EndpointMethods.GET,
          url: "/search-oe/{idVehicle}/{idItem}/{oeCode}?idVehicle={idVehicle}&idItem={idItem}&oeCode={oeCode}&skipCheck={skipCheck}",
        },
        suppliersFromOEM: {
          method: EndpointMethods.GET,
          url: "/supplier/{id}/search-oe/{idVehicle}/{idItem}/{oeCode}",
        },
        getFromList: {
          method: EndpointMethods.GET,
          url: "/get-from-brand/{listkr}/{oekr}?idList={listkr2}&code={oekr2}&skipCheck={skipCheck}",
        },
        suppliersFromList: {
          method: EndpointMethods.GET,
          url: "/supplier/{id}/specific/{listkr}/{oekr}",
        },
        getProductMultipleSuppliers: {
          method: EndpointMethods.GET,
          url: "/brand-code/{idBrand}/{idKromeda}",
        },
        getSingleProductFromSupplier: {
          method: EndpointMethods.GET,
          url: "/supplier/{idSupplier}/brand-code/{idBrand}/{idKromeda}"
        },
        vinSettings: {
          method: EndpointMethods.GET,
          url: "/vin-settings",
        },
        plateSettings: {
          method: EndpointMethods.GET,
          url: "/plate-settings",
        },
        walletAvailability: {
          method: EndpointMethods.GET,
          url: "/availability",
        },
        getWalletType: {
          method: EndpointMethods.GET,
          url: "/wallet/{type}"
        },
        compatibilities: {
          method: EndpointMethods.GET,
          url: "/compatibilities/{id}",
        },
        customCategories: {
          method: EndpointMethods.GET,
          url: "/custom-categories/{category}",
        },
        customCategoriesProducts: {
          method: EndpointMethods.GET,
          url: "/custom-category/{id}?id={id2}&skipCheck={skipCheck}",
        },
        supplierCategoriesProducts: {
          method: EndpointMethods.GET,
          url: "/custom-category/{id}/supplier/{idSupplier}",
        },
        customerOrders: {
          method: EndpointMethods.GET,
          url: "/customer-orders",
        },
        singleOrder: {
          method: EndpointMethods.GET,
          url: "/customer-order/{code}/{year}",
        },
        addToCart: {
          method: EndpointMethods.POST,
          url: "/add-to-cart",
        },
        setToCart: {
          method: EndpointMethods.POST,
          url: "/set-to-cart",
        },
        removeFromCart: {
          method: EndpointMethods.POST,
          url: "/remove-from-cart",
        },
        removeCart: {
          method: EndpointMethods.POST,
          url: "/remove-cart",
        },
        buyVin: {
          method: EndpointMethods.POST,
          url: "/buy-vin",
        },
        buyPlate: {
          method: EndpointMethods.POST,
          url: "/buy-plate",
        },
        buyWallet: {
          method: EndpointMethods.POST,
          url: "/buy-wallet",
        },
        searchList: {
          method: EndpointMethods.POST,
          url: "/search-list",
        },
        allSearches: {
          method: EndpointMethods.POST,
          url: "/search-products",
        },
        redirect: {
          method: EndpointMethods.GET,
          url: "/redirect",
        }
      }
    },
  },

  auth: {
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://auth.tulero.it',
    endpoints: {
      identity: '/identity',
      logout: '/logout',
      getUser: '/current-user',
      getPermission: '/permission/{permission}'
    },
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbiI6IlRVTEVSTy1TSE9QLUIyQiIsImlhdCI6MTY2ODc5MjkyNSwiZXhwIjoxNzAwMzI4OTI1fQ.qcVUumTU5l3ygjhcgFAtPponXBDyVp52NwfoFVixc5s'
  },
  b2b: {
    // baseUrl: "http://localhost:3000",
    // baseUrl: "https://b2b-api.tulero.it",
    baseUrl: "https://api.tulero.it",
    endpoints: {
      getVinSession: {
        url: "/vehicle/vin/{vin}",
        permission: "orders.read"
      },
      getVehicleFromPlate: {
        url: "/vehicle/plate/{plate}",
        permission: "orders.read"
      },
      getVehicleInfo: {
        url: "/vehicle/info/{idVehicle}",
        permission: "orders.read"
      },
      getVehicleFilters: {
        url: "/vehicle/filters/{type}",
        permission: "orders.read"
      },
      getVehicleBrands: {
        url: "/vehicle/brands",
        permission: "orders.read"
      },
      getVehicleModels: {
        url: "/vehicle/brands/{brand}/models",
        permission: "orders.read"
      },
      getVehicleVersions: {
        url: "/vehicle/brands/{model}/versions",
        permission: "orders.read"
      },
      getCategories: {
        url: "/vehicle/categories/{idVehicle}",
        permission: "orders.read"
      },
      getSubcategories: {
        url: "/vehicle/subcategories/{idVehicle}/{idGroup}",
        permission: "orders.read"
      },
      getParts: {
        url: "/vehicle/parts/{idVehicle}/{idSubgroup}",
        permission: "orders.read"
      },
      getCriteria: {
        url: "/vehicle/criteria/{idVehicle}/{idPart}",
        permission: "orders.read"
      },
      getProducts: {
        url: "/product/parts/{idVehicle}/{idPart}/{oem}",
        permission: "orders.read"
      },
      getProductsByText: {
        url: "/product/parts/{text}",
        permission: "orders.read"
      },
      getSuppliers: {
        url: "/supplier",
        permission: "orders.read"
      },
      getProductsSupplier: {
        url: "/supplier/{idSupplier}",
        permission: "orders.read"
      },
      getProductsSupplierSearch: {
        url: "/supplier/search/{idSupplier}",
        permission: "orders.read"
      },
      getProductsSupplierFilter: {
        url: "/supplier/search-filter/{idSupplier}/{type}",
        permission: "orders.read"
      },
      getCurrentBusiness: {
        url: "/business/current",
        permission: "orders.read"
      },
      getCart: {
        url: "/cart",
        permission: "orders.read"
      },
      updateCart: {
        url: "/cart/update-product",
        permission: "orders.read"
      },
      deleteProductCart: {
        url: "/cart/delete-product",
        permission: "orders.read"
      },
      deleteCart: {
        url: "/cart/delete",
        permission: "orders.read"
      },
      getCustomCategories: {
        url: "/category",
        permission: "orders.read"
      },
      getCustomCategoryProducts: {
        url: "/category/{id}",
        permission: "orders.read"
      },
      getBusiness: {
        url: "/business/current",
        permission: "orders.read"
      },
      checkout: {
        url: "/cart/confirm",
        permission: "orders.read"
      },
      getOrders: {
        url: "/order/list",
        permission: "orders.read"
      },
      getSingleOrder: {
        url: "/order/{id}",
        permission: "orders.read"
      },
      removeCart: {
        url: "/cart/remove",
        permission: "orders.read"
      },
      getProductCompatibilities: {
        url: "/product/{idBrand}/{idKromeda}/comatibilities",
        permission: "orders.read"
      },
      getCurrentAgreements: {
        url: "/business/current/agreements",
        permission: "orders.read"
      },
      getBanners: {
        url: "/banner",
        permission: "orders.read"
      },
      updateBusiness: {
        url: "/business/current",
        permission: "orders.read"
      },
      getOrderDeliveries: {
        url: "/delivery/order/{id}",
        permission: 'orders.read'
      },
      downloadOrdersXLS: {
        url: "/order/all/xls",
        permission: 'orders.read'
      },
      downloadOrdersCSV: {
        url: "/order/all/csv",
        permission: 'orders.read'
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

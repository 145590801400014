import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreLoadingScreenService} from "../../@core/services/loading-screen.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeLogoService {
  logoTheme: string;

  constructor(private loadingScreenService: CoreLoadingScreenService) {}

  setThemeLogo(logo: string): void {
    this.loadingScreenService.show();
    this.logoTheme = logo;
    setTimeout(() => {
      this.loadingScreenService.hide();
    }, 5000);
  }
  getThemeLogo(): string {
    return this.logoTheme;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduce'
})
export class ReducePipe implements PipeTransform {
  transform(elements: Array<any>, property?: string, initial?: any): any {
    if (!elements) {
      return;
    }
    if (!initial) {
      initial = 0;
    }
    return elements.reduce((total, element) => {
      if (property) {
        return total + element[property];
      } else {
        return total + element;
      }
    }, initial);
  }
}

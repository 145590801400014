import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  transform(price: number, format?: string): any {
    if (isNaN(price)) {
      return price;
    }
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    let formatArray
    if (!format) {
      formatArray = [];
    } else {
      formatArray = format.split('|')
    }
    const [currency = '€ ', thousand = '.', decimal = ',', precision = '2'] = formatArray;
    const number = price.toFixed(Math.max(0, ~~precision));
    const parts = number.split('.');
    const dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);
    const cents = parts[1] ? decimal + parts[1] : '';
    return currency + dollars + cents;
  }
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {CoreCommonModule} from '@core/common.module';
import {CoreTouchspinModule} from '@core/components/core-touchspin/core-touchspin.module';

import {NavbarComponent} from 'app/layout/components/navbar/navbar.component';
import {NavbarBookmarkComponent} from 'app/layout/components/navbar/navbar-bookmark/navbar-bookmark.component';
import {NavbarCartComponent} from 'app/layout/components/navbar/navbar-cart/navbar-cart.component';
import {
  NavbarNotificationComponent
} from 'app/layout/components/navbar/navbar-notification/navbar-notification.component';
import {CoreCardModule} from "@core/components/core-card/core-card.module";
import {NouisliderModule} from "ng2-nouislider";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarBookmarkComponent,
    NavbarCartComponent,
    NavbarNotificationComponent
  ],
  imports: [RouterModule, NgbModule, CoreCommonModule, PerfectScrollbarModule, CoreTouchspinModule, CoreCardModule, NouisliderModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {
}

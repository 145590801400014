export const list = [
  {
    "id": "65558e98aaa8a6e49e8580dd",
    "status": "COMPLETED",
    "code": "B-003978",
    "name": "OFFICINE CAVALLO",
    "vat": "05375670659",
    "location": {
      "country": "IT",
      "region": "Campania",
      "province": "SA",
      "city": "MONTECORVINO ROVELLA",
      "zip": "84096",
      "street": "CDA CAPPELLA",
      "number": "1"
    },
    "pec": "",
    "uniqueCode": "",
    "network": {
      "id": "650b0ea572f3409632912122",
      "code": "EKPARTS",
      "name": "EK Parts",
      "logo": "",
      "createdAt": "2023-11-16T04:29:51.724Z",
      "updatedAt": "2023-11-16T04:29:51.724Z"
    },
    "group": {
      "id": "6552e9bfab56560ce03f2521",
      "code": "network-agente-25",
      "name": "Network con agente - 25%",
      "description": ""
    },
    "contacts": [
      {
        "name": "",
        "phone": "3397804641",
        "email": "officina.cavallo@gmail.com"
      }
    ],
    "payments": [],
    "addresses": [
      {
        "number": "1",
        "city": "MONTECORVINO ROVELLA",
        "country": "IT",
        "province": "SA",
        "region": "Campania",
        "street": "CDA CAPPELLA",
        "zip": "84096",
        "extra": {
          "cati": {
            "username": "",
            "password": ""
          },
          "ovam": {
            "code": "123654"
          }
        }
      },
      {
        "number": "51",
        "city": "DELLA VENTAGLIA",
        "country": "IT",
        "province": "SA",
        "region": "Campania",
        "street": "CDA CAPPELLA",
        "zip": "84096",
        "extra": {
          "cati": {
            "username": "",
            "password": ""
          },
          "ovam": {
            "code": "123654"
          }
        }
      }
    ],
    "wallets": [],
    "createdAt": "2023-11-15T13:17:19.828Z",
    "updatedAt": "2023-11-15T13:17:47.020Z"
  }
];

export const viewFromCode = (body: any, keys: string[]) => {
  const code = keys[2];
  return list.find(business => business.code === code);
}

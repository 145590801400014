import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "app/auth/service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class ApiService {
  private url: string;
  private endpoints: any;
  private token: string;

  constructor(private http: HttpClient, private auth: AuthenticationService, private _router: Router) {}

  setUp(url, endpoints, token?) {
    this.url = url;
    this.endpoints = endpoints;
    this.token = token;
  }

  getUrl(endpoint, params?) {
    let url = this.url + endpoint;
    if (params) {
      Object.keys(params).forEach((key, index) => {
        url = url.replace('{' + key + '}', params[key]);
      });
    }
    if (this.token) {
      url = url + '?token=' + this.token;
    }
    return url;
  }

  async getOptions(options, permission?) {
    if (permission) {
      //const token = await this.auth.getPermissionToken(permission);
      // if (!token) {
      //   this._router.navigate(["/errors/not-authorized"]);
      //   // redirect to unauthorized page
      //   return false;
      // }
      if (!options) {
        options = {};
      }
      if (!options.headers) {
        options.headers = {};
      }
     // options.headers.Authorization = 'Bearer ' + token;
    }
    if (options.params !== undefined) {
      let params = {}
      this.buildHttpParams(params, options.params, "");
      options.params = params;
    }
    return options;
  }
  private buildHttpParams(params: any, data: any, currentPath: string) {
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        this.buildHttpParams(params, data[key], `${currentPath}${key}.`);
      } else {
        params[`${currentPath}${key}`] = data[key];
      }
    });
  }

  async get(endpoint, params?, options?) {
    endpoint = this.endpoints[endpoint];
    let needPermission = false;
    if (typeof endpoint !== 'string') {;
      needPermission = endpoint.permission;
      endpoint = endpoint.url
    }
    options = await this.getOptions(options, needPermission);
    let url = this.getUrl(endpoint, params);
    return this.http.get(url, options);
  }

  async post(endpoint, body, params?, options?) {
    endpoint = this.endpoints[endpoint];
    let needPermission = false;
    if (typeof endpoint !== 'string') {
      needPermission = endpoint.permission;
      endpoint = endpoint.url
    }
    options = await this.getOptions(options, needPermission);
    let url = this.getUrl(endpoint, params);
    return this.http.post(url, body, options);
  }

  async patch(endpoint, body, params?, options?) {
    endpoint = this.endpoints[endpoint];
    let needPermission = false;
    if (typeof endpoint !== 'string') {
      needPermission = endpoint.permission;
      endpoint = endpoint.url
    }
    options = await this.getOptions(options, needPermission);
    let url = this.getUrl(endpoint, params);
    return this.http.patch(url, body, options);
  }

  async delete(endpoint, params?, options?) {
    endpoint = this.endpoints[endpoint];
    let needPermission = false;
    if (typeof endpoint !== 'string') {
      endpoint = endpoint.url;
      needPermission = endpoint.perrmission;
    }
    options = await this.getOptions(options, needPermission);
    let url = this.getUrl(endpoint, params);
    return this.http.delete(url, options);
  }

  async put(endpoint, body, params?, options?) {
    endpoint = this.endpoints[endpoint];
    let needPermission = false;
    if (typeof endpoint !== 'string') {
      endpoint = endpoint.url;
      needPermission = endpoint.perrmission;
    }
    options = await this.getOptions(options, needPermission);
    let url = this.getUrl(endpoint, params);
    return this.http.put(url, body, options);
  }
}

import {Injectable} from "@angular/core";
import {ApiService} from "app/auth/helpers/api.service";
import {environment} from "environments/environment";
import {BehaviorSubject} from "rxjs";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class ShopB2bService {

  private store = {}

  constructor(private _apiService: ApiService) {
    this._apiService.setUp(environment.b2b.baseUrl, environment.b2b.endpoints);
  }

  getBehaviorSubject(name, defaultValue = {}) {
    if (!this.store[name]) {
      this.store[name] = new BehaviorSubject(defaultValue);
    }
    return this.store[name];
  }

  // getCurrentBusiness(name) {
  //   return new Promise(async (resolve, reject) => {
  //     const request = await this._apiService.get('getCurrentBusiness', {}, {})
  //     request.subscribe((response: any) => {
  //       const result = response.result
  //       let singleStore = {
  //         data: undefined,
  //         total: undefined
  //       };
  //       singleStore.data = result;
  //       singleStore.total = 1;
  //       this.getBehaviorSubject(name).next(singleStore);
  //       resolve(singleStore);
  //       return singleStore;
  //     }, reject);
  //   });
  // }

  getCurrentAgreements(name): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // const request = await this._apiService.get('getCurrentAgreements', {}, {})
      // request.subscribe((response: any) => {
      //   resolve(response.result)
      // })
    });
  }
  sendAgreements(name, agreements): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('getCurrentAgreements', {agreements}, {}, {})
      request.subscribe((response: any) => {
        resolve(response.result)
      })
    });
  }

  getVehicleBrands(name) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleBrands', {}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.brands;
        singleStore.total = result.brands.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVehicleModels(name, brand) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleModels', {brand}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.models;
        singleStore.total = result.models.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVehicleVersions(name, model) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleVersions', {model}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.versions;
        singleStore.total = result.versions.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getCategories(name, idVehicle) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getCategories', {idVehicle}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.categories;
        singleStore.total = result.categories.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getSubcategories(name, idVehicle, idGroup) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getSubcategories', {idVehicle, idGroup}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.subcategories;
        singleStore.total = result.subcategories.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getParts(name, idVehicle, idSubgroup) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getParts', {idVehicle, idSubgroup}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.parts;
        singleStore.total = result.parts.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getCriteria(name, idVehicle, idPart) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getCriteria', {idVehicle, idPart}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.criteria;
        singleStore.total = result.criteria.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVehicleByPlate(name, plate): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleFromPlate', {plate}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.plateData;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVinSession(vin) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVinSession', {vin}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.plateData;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVehicleInfo(name, idVehicle) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleInfo', {idVehicle}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.vehicleInfo;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getVehicleFilters(name, type) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getVehicleFilters', {type}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.filters;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }


  getProducts(name, idVehicle, idPart, oem): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getProducts', {idVehicle, idPart, oem}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductsFromText(name, text): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getProductsByText', {text}, {})
      request.subscribe((response: any) => {
        const result = response.result.products
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.products;
        singleStore.total = result.count;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getSuppliers(name, category = null): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const options = category ? {params: {category}} : {};
      const request = await this._apiService.get('getSuppliers', {}, options)
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductsSupplier(name, idSupplier, products): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('getProductsSupplier', {products}, {idSupplier}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductsSupplierSearch(name, idSupplier, search): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('getProductsSupplierSearch', {search}, {idSupplier}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductsSupplierFilter(name, idSupplier, type, filter): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('getProductsSupplierFilter', {filter}, {idSupplier, type}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getCart(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      // const request = await this._apiService.get('getCart', {}, {})
      // request.subscribe((response: any) => {
      //   const result = response.cart
      //   let singleStore = {
      //     data: undefined,
      //     total: undefined
      //   };
      //   singleStore.data = result;
      //   singleStore.total = result.totalProducts;
      //   this.getBehaviorSubject(name).next(singleStore);
      //   resolve(singleStore);
      //   return singleStore;
      // }, reject);
    });
  }

  addProductToCart(name, product, supplier, quantity): Promise<{data: any, total: number}> {
    quantity = parseInt(quantity);
    const margin = quantity % product.package.quantity;
    if (margin > 0 ) {
      quantity += product.package.quantity - margin;
    }
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('updateCart', {
        product: product,
        quantity: quantity,
        supplier: supplier,
        operation: 'add'
      }, {}, {})
      request.subscribe((response: any) => {
        const result = response.cart
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.totalProducts;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  updateProductToCart(name, product, supplier, quantity) {
    quantity = parseInt(quantity);
    const margin = quantity % product.package.quantity;
    if (margin > 0 ) {
      quantity += product.package.quantity - margin;
    }
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('updateCart', {
        product: product,
        quantity: quantity,
        supplier: supplier,
        operation: 'replace'
      }, {}, {})
      request.subscribe((response: any) => {
        const result = response.cart
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.totalProducts;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  removeProductToCart(name, product, supplier) {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('deleteProductCart', {
        product: product,
        supplier: supplier,
      }, {}, {})
      request.subscribe((response: any) => {
        const result = response.cart
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.totalProducts;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getCustomCategories(name, category): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      category = category.toUpperCase()
      const request = await this._apiService.get('getCustomCategories', {}, {params: {category}})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductsFromCategory(name, id): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getCustomCategoryProducts', {id}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getBusiness(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getBusiness', {}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = 1;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  checkout(name, payment, orders?): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.post('checkout', {payment, orders}, {})
      request.subscribe((response: any) => {
        if (response.payment?.redirect) {
          window.location.href = response.payment.redirect;
          return ;
        }
        const result = response.cart
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.totalProducts;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  removeCart(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('removeCart', {}, {})
      request.subscribe((response: any) => {
        const result = response.cart
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.totalProducts;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getOrders(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getOrders', {}, {})
      request.subscribe((response: any) => {
        const result = response.orders
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  downloadOrdersXLS(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('downloadOrdersXLS', {}, {responseType: 'blob'})
      request.subscribe(
        data => {
          saveAs(data, name + '.xlsx');
          resolve({data: "done", total: 0} as any);
        },
        err => {
          // alert("Problem while downloading the file.");
          console.error(err);
          reject(err);
        }
      );
    });
  }

  downloadOrdersCSV(name): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('downloadOrdersCSV', {}, {responseType: 'blob'})
      request.subscribe(
        data => {
          saveAs(data, name + '.csv');
        },
        err => {
          // alert("Problem while downloading the file.");
          console.error(err);
        }
      );
    });
  }

  getOrder(name, id): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getSingleOrder', {id}, {})
      request.subscribe((response: any) => {
        const result = response.order
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = 1;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getDeliveries(name, id): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getOrderDeliveries', {id}, {})
      request.subscribe((response: any) => {
        const result = response
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getProductCompatibilities(name, idBrand, idKromeda): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getProductCompatibilities', {idBrand, idKromeda}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result.compatibilities;
        singleStore.total = result.length;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }

  getCurrentBanners() {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.get('getBanners', {}, {})
      request.subscribe((response: any) => {
        resolve(response);
      });
    });
  }

  updateCurrentBusiness(name, business): Promise<{data: any, total: number}> {
    return new Promise(async (resolve, reject) => {
      const request = await this._apiService.patch('updateBusiness', business, {}, {})
      request.subscribe((response: any) => {
        const result = response.result
        let singleStore = {
          data: undefined,
          total: undefined
        };
        singleStore.data = result;
        singleStore.total = 1;
        this.getBehaviorSubject(name).next(singleStore);
        resolve(singleStore);
        return singleStore;
      }, reject);
    });
  }
}
